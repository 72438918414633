<style scoped lang='scss'>
.OS_sol_title{
  display: inline-block;
  font-size: 24px;
  margin-bottom: 24px;
  padding-bottom: 6px;
  margin-left: 10px;
  border-bottom: 1px solid #000;
}
.main_introduce{
  padding: 0 40px;
  .title2{
    font-size: 24px;
  }
  .colorTit{
    margin: 20px 0;
    font-size: 16px;
  }
  .center-block{
    margin-top: 30px !important;
    margin-bottom: 20px !important;
  }
  .iconListBox{
    width: 900px;
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 34%);
    margin: 10px;
    border-radius: 12px;
    padding: 10px;
    img{
      width: 880px;
    }
  }
  .video_tx{
    padding-left: 10px;
    margin-top: 40px;
    .listTit{
      font-size: 24px;
      color: #2574b0;
      padding: 10px 0;
    }
    .list_item{
      .list_item_tit{
        font-size: 19px;
        margin-bottom: 20px;
      }
      ul{
        padding-left: 76px;
        margin-bottom: 40px;
      }
      ul li{
        list-style: initial;
        line-height: 26px;
      }
    }
    p{
      font-size: 16px;
      line-height: 1.6em;
      margin: 1rem 0  ;
    }
  }
  .video_yy{
    margin-top: 60px;
    .video_yy_Img{
      width: 100%;
      background-color: #e6e6e6;
      box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
      margin-bottom: 60px;
      border-radius: 16px;
      padding: 30px;
      img{width: 100%;}
    }
  }
  .video_XX{
    padding-bottom: 60px;
  }
  .video_product{
    .video_product_Title{
      font-size: 24px;
      margin: 0 0 30px;
      padding: 0 0 6px;
    }
    .item{
      width: 50%;
      div{
        line-height: 1.8rem;
        font-size: 20px;
      }  
      .item_left{ width: 282px; }
      .item_right{ width: 177px; }
    }
  }
}
</style>

<template>
  <div id="video_processing">
    <div class="width rullMain">
      <div class="main_introduce">      
        <div class="title textCenter weight">视频处理解决方案</div>
        <div class="title2">以提供业界最高品质的影像图形产品平台为目标</div>
        <div class="colorTit">我司在图像、影像编码、解码等依存硬件部分的开发方面积累了很多技术诀窍，为给各种各样的客户提供稳定的应用开发做出了贡献。</div>
        <img class="center-block" src="@/assets/images/business/3/sol_eizo_main.png" alt="">

        <div class="iconListBox">
          <img src="@/assets/images/business/3/sol_eizo_02-1536x497.png" alt="">
        </div>

        <div class="video_tx">
          <div class="listTit weight">平台特征</div>
          <div class="list_item">
            <div class="list_item_tit weight">高品质用户体验</div>
            <ul>
              <li>为了将HW性能发挥到极限，通过调谐的OS实现高速启动、高实时性</li>
              <li>用低层驱动程序最大限度地发挥与显示相关的硬件性能</li>
              <li>通过GPU加速+游戏应用引擎实现高表现力画面显示</li>
            </ul>
          </div>
          <div class="list_item">
            <div class="list_item_tit weight">敏捷地开发应用程序</div>
            <ul>
              <li>VividOS+游戏应用引擎可以简单、敏捷地实现产品开发</li>
              <li>通过VividOS的产品Layer管理系统，最大限度地实现功能模块的挪用</li>
            </ul>
          </div>
          <div class="list_item">
            <div class="list_item_tit weight">高度的差别化</div>
            <ul>
              <li>VividOS通过结合硬件的高级定制开发，能够根据客户的要求实现独一无二的产品形态</li>
            </ul>
          </div>
          <div class="list_item">
            <div class="list_item_tit weight">低成本</div>
            <ul>
              <li>高度定制的OS内核，实现更小的RAM使用量和Flash使用量</li>
            </ul>
          </div>
        </div>

        <div class="video_XX">
          <div class="OS_sol_title">平台架构</div>
          <img src="@/assets/images/business/3/sol_eizo_03-1.png" alt="">
          <div class="video_tx">
            <div class="listTit">为什么需要我们的平台？ - 简单而高功能</div>
            <p>高端的消费者产品（智能手机、家用游戏机等）系统随着时代的发展而大规模化、复杂化、高性能化，以Android为代表的OS变得相当肥大，被要求的HW也变得高规格化。</p>
            <p>另一方面，低端产品（IoT产品、单一功能家电等）的软件系统功能过于简单，用户体验有改善的余地。</p>
            <p>综上所述，市场上的软件平台有两个极端发展的倾向，特别是对于低端到中端的电器，在简单和高功能之间几乎没有取得平衡的解决方案。</p>  
          </div>
          <div class="video_yy">
            <div class="video_yy_Img">
              <img src="@/assets/images/business/3/sol_eizo_04.png" alt="">
            </div>
            <div class="video_tx">
              <div class="listTit">为什么需要我们的平台？ - 高度的差别化</div>
              <p>固有类型的电气化产品（相机、电视、数字标牌等）随着商品化的推进，制造商之间很容易出现价格竞争。对于顾客的定制化、功能差别化的要求，好的解决方案很少。</p>
              <p>在我司的平台上，可以配合硬件，根据客户的要求，实现硬件、软件的高度定制，制作独一无二的产品形态。</p>
              <p>利用Allwinner公司SoC擅长的超高精细视频编码、解码，通过图像、相机相关的影像解决方案，向客户提供高品质的压缩、网络发布、图像解析、平滑的再生。</p>
            </div>
          </div> 
        </div>
        
        <div class="video_product">
          <div class="video_product_Title">功能差别化产品的例子</div>
          <div class="acea-row row-between-wrapper">
            <div class="item">
              <img class="item_left" src="@/assets/images/business/3/sol_eizo_05.png" alt="">
            </div>
            <div class="item">
              <div>2个实时输入FullHD影像</div>
              <div>通过效果处理流传送装置</div>
            </div>
          </div>
          <div class="acea-row row-between-wrapper">
            <div class="item">
              <div>4k对应的可显示</div>
              <div>4面的室内装饰型数字标牌</div>
            </div>
            <div class="item">
              <img class="item_right" src="@/assets/images/business/3/sol_eizo_06.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "video_processing",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    window.scrollTo({top: 0,left: 0});
  },
  methods: {},
};
</script>
